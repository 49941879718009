.App {
  text-align: center;
}
.splash{
  z-index: 2;
  width: 100vw;
  height: 100vh;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.lcontent{
  min-height: 100%;
  width: 100%;
  padding: 35%;
}

.metamaskpanel{
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 99;
}

.centerwconbtn{
  position: absolute;
  top: 0px;
  z-index: 2;
  width: 100%;
  text-align: center;
}
.metazooielogotop{
  margin-top: 50px;
}
.cywt{
  margin-top: 10%;
}
.mtbutton{
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  background-color: #8167f1;
  color:white;
  border: 3px solid #010305;
  border-radius: 5px;
  width: 200px;
  height: 60px;
  cursor:pointer;
}
.mtbtnt{
  line-height: 50px;
}
.mtbtnimg{
  margin-top: 5px;
  height: 40px;
  width: 40px;
  float: left;
  margin-left: 25px;
}
.prevtxtskip{
  margin-top: 30px;
}
.txtskipfornnow{
  margin-top: 30px;
}
.btnskip{
  background: #8167f1;
  color:white;
  padding: 10px 20px 10px 20px;
  border-radius: 5px;
  border: solid 1px black;
  font-size:1.2em; 
}
.topadjust{
  margin-top: -40% !important;
}
.hidemtbtn{
  display: none;
  visibility: hidden;
}
.absoluta{
  position: absolute;
  z-index: 50;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
}
.displaykeycontrols{
  display:block;
}
.hidekeycontrols{
  display:none;
}
.dirKey{
  position:absolute;
  z-index: 50;
  top:28vh;
  left:25vw;
  text-align: center;
  opacity: 1;
}
.jumpKey{
  position:absolute;
  z-index: 50;
  top:28vh;
  left:75vw;
  text-align: center;
}
.space{
  position: absolute;
  z-index: 50;
  top:18vh;
  left:60vw;
  text-align: center;
  width: 120px;
  height: 30px;
  line-height: 30px; 
  border-radius:3px;
  border: solid 1px white;
  box-shadow: 1px 1px 0px 1px white;
}
.wasd{
  position: absolute;
  z-index: 50;
  top:18vh;
  left:13vw;
  width: auto;
  opacity: 1;
  border: solid 1px white;
}
.W{
  opacity: 1;
  top:14vh;
  width: 30px;
  height: 30px;
  line-height: 30px; 
  border-radius:3px;
  box-shadow: 1px 1px 0px 1px white;
}
.A{
  left: 10vw;
  width: 30px;
  height: 30px;
  line-height: 30px; 
  border-radius:3px;
  box-shadow: 1px 1px 0px 1px white;
}
.S{
  /*top:35vh;*/
  left: 13vw;
  width: 30px;
  height: 30px;
  line-height: 30px; 
  border-radius:3px;
  box-shadow: 1px 1px 0px 1px white;
}
.D{
  left: 16vw;
  width: 30px;
  height: 30px;
  line-height: 30px; 
  border-radius:3px;
  box-shadow: 1px 1px 0px 1px white;
}
.anim{
  opacity: 1;
  transition: opacity 30s;
  opacity: 0;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

i.icon.hidden {
  display: none;
}

#playButton {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: scale(5, 5);
}

.fullscreen {
  height: 100%;
}

video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.logobacking {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: rgb(250, 250, 250);
  border-radius: 50% 50%;
  width: 160px;
  height: 160px;
}

.play {
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  margin: 5px;
  width: 64px;
  height: 64px;
  fill: black;
}

.stopped {
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  margin: 5px;
  width: 44px;
  height: 44px;
  fill: #9e9e9e;
}

.logo {
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  margin: 5px;
  width: 128px;
  height: 128px;
}
.logo path {
  stroke-dasharray: 315;
  stroke-dashoffset: 315;
  animation: dash 1.5s alternate infinite;
  stroke: #9e9e9e;
  stroke: width 1.2px;
  fill: white;
}

.logo g {
  animation: fill-in 1.5s 1.5s alternate infinite;
  fill-opacity: 1;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fill-in {
  to {
    fill-opacity: 1;
  }
}

.hidden {
  display: none !important;
}
